.test{
  background-color: purple;
}
.test2{
  background-color: green;
}
.top{
  margin-top:3%;
  @include  breakpoint(medium up){
    margin-top:5%;
  }
  @include  breakpoint(large up){
    margin-top:4%;
  }
}
::selection {
    background: $blau;
    color: white;
}

header{
  position:relative;
}
.downloadimg{
  width:50%;
  margin:0 auto;
  img{
    width:100%;
  }
}
.bla{
  background-color: red;
  position: absolute;
  left: 0;
 right: 0;
 margin-left: auto;
 margin-right: auto;
}
.headlogos{
  overflow: hidden;
  // background-color: blue;
  position: absolute;
    z-index: 100;
    top:550px;
    width:220px;
    height:200px;
    right:0;
}
.headlogosone {
  position: absolute;
  overflow: hidden;
  z-index: 100;
  top:0%;
  right:-600px;
  img{
    width:200px;
    margin-bottom:14px;
    float:right;
  }
}
.headlogostwo {
  position: absolute;
  overflow: hidden;
  z-index: 100;
  top:40%;
  right:-600px;
  // background-color: red;
  width:220px;
  height:100px;
  img{
    width:200px;
    margin-top:14px;
    margin-bottom:14px;
    float:right;
    -webkit-box-shadow: -1px 3px 18px 6px rgba(0,0,0,0.29);
    -moz-box-shadow: -1px 3px 18px 6px rgba(0,0,0,0.29);
    box-shadow: -1px 3px 18px 6px rgba(0,0,0,0.29);
  }
}
#wrapper{
  background-color: $white;
  width:100vw;
  position: relative;
}
.button{
  border:solid 2px $black;
  background-color: rgba(0,0,0,0);
  color:$black;
  padding: 0.85em 1.4em;
}
.button:focus, .button:hover {
  background-color: rgba(0,0,0,0);
}
#wrapper p a{
  // border:solid 2px $black;
  // background-color: $orange;
  // color:$white;
  // padding: 0.85em 1.4em;
  // transition: 0.6s ease-out;
  text-decoration: underline;
  COLOR:$orange;
}
#wrapper p a:hover{
    // background-color: darken($orange,15%) !important;
    transition: 0.6s ease-out;
    // color:$white !important;
    color:darken($orange,15%) !important;
}
#wrapper p a[href^="mailto:"]{
  background-color: white;
  color:$black;
  padding: 0em;
    transition: 0.6s ease-out;
}
#wrapper p a[href^="mailto:"]:hover{
  background-color: white !important;
  color:$orange !important;
  padding: 0em;
    transition: 0.6s ease-out;
}
.blau p a{
  color:inherit !important;
  background-color: inherit !important;
  padding:0em !important;
}
#wrapper .blau p a:hover{
  color:$orange !important;
  background-color: $blau !important;
  transition: 0.6s ease-out;
}
.contentbox{
  padding:2rem;
  position: relative;
  background-color: $white;
  @include breakpoint(xlarge up){
  padding:4rem 4rem 2rem 4rem;
  }
}
.animation{
  opacity: 0;
  transition: all .9s cubic-bezier(0.75, 0.05, 0, 1.14);
}
.animationshow {
  opacity: 1;
}

.content{
  padding:2rem;
  @include breakpoint(xlarge up){
  padding:2rem 4rem 2rem 4rem;
  // margin-bottom:4rem;
  }
}


.overlaycontenttop{
  // padding:2rem;
  margin-top:6%;
  position: absolute;
  width:100%;
  @include breakpoint(xlarge up){
  // padding:2rem 4rem 2rem 4rem;
  }
}
.overlaycontent{
  // padding:2rem;
  bottom:10%;
  position: absolute;
  width:100%;
  @include breakpoint(xlarge up){
  // padding:2rem 4rem 2rem 4rem;
  }
}
.overlayinlay{
  background-color: $orange;
  color:$white !important;
  padding:2rem 0.9375rem;
  max-width: 75rem;
  margin: 0 auto;
}
.overlayinlaynocolor{
  // background-color: $orange;
  color:$white !important;
  padding:2rem 0.9375rem;
  max-width: 75rem;
  margin: 0 auto;
}
.img{
  max-width:70%;
  margin: 0 auto;
  padding:4% 0%;
  img{
    width:100%;
  }
}
.fullsizeimg{
  max-width:100%;
  margin: 0 auto;
  // padding:4% 0%;
  img{
    width:100%;
  }
}
.startimg{
  max-height:300px;
@include breakpoint(large up){
  max-height:500px;
  overflow: hidden;
  position: relative;
  // max-height:300px;
  width:auto;}
  img{
    @include breakpoint(large up){
    min-height:100%;
    min-width:100%;
    overflow: hidden;
    max-width: none;
  }
}
}
.white{
    color:$white !important;
}
.yellow, .yellowleft, .gelb{
  background-color: $gelb;
  position: relative;
  color:$black !important;
}
.blue, .blueright, .blau{
  background-color: $blau;
  position: relative;
}
.orange, .orangeleft, .orangee{
  position: relative;
  background-color: $orange;
}
.yellowleft::before{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $gelb;
   bottom:-44px;
   right:50%;
   // right:calc(50%-40px);
  @include breakpoint(large up){
   top:176px;
   right:-44px;
   }
}
.yellow::before{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $gelb;
   top:-44px;
   right:50%;
     @include breakpoint(large up){
       top:176px;
       left:-44px;
     }
}
.orange::before{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $orange;
   top:-44px;
   right:50%;
     @include breakpoint(large up){
       top:176px;
       left:-44px;
     }
}
.orangeleft::before{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $orange;
   bottom:-44px;
   right:50%;
  @include breakpoint(large up){
   top:176px;
   right:-44px;
   }
}
.blue::after{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   bottom:-44px;
   right:50%;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $blau;
   @include breakpoint(large up){
    top:176px;
    right:-44px;
    }
}
.blueright::after{
  content: '';
   display: inline-block;
   position: absolute;
   z-index: 200;
   top:-44px;
   left:40%;
   width: 88px;
   height: 88px;
   -moz-border-radius: 44px;
   -webkit-border-radius: 44px;
   border-radius: 44px;
   background-color: $blau;
   @include breakpoint(large up){
    top:176px;
    left:-44px;
    }
}
.blue{
  background-color: $blau;
  position: relative;
}
.orange{
  position: relative;
  background-color: $orange;
}
.logo{
  margin-top:20px;
  width:150px;
  height: auto;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  transition: all 0.6s ease;
  position: absolute;
  left: 0;
 right: 0;
 margin-left: auto;
 margin-right: auto;
 @include breakpoint(medium up){
     margin-top:10px;
     width:250px;
 }
 @include breakpoint(large up){
   position: relative;
      width:100%;
      margin-top: 20px;
 }
}
.logo.move{
  height: auto;
  margin-top:8px;
  width:100px;
   @include breakpoint(large up){
    width:60%;
    height: auto;
    margin-top:8px;
  }
}
.wettebewerbe{
  position: fixed;
  top:20%;
  z-index: 1000;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  transition: all 0.6s ease;
  cursor: pointer;
  img{
    -webkit-transition: all 0.6s ease;
    -moz-transition: all 0.6s ease;
    -o-transition: all 0.6s ease;
    -ms-transition: all 0.6s ease;
    transition: all 0.6s ease;
    width:70%;
    height:auto;
  }
    :hover{
      opacity: 0.8;
      transition: 0.6s ease-in-out;
    }
}
.wettebewerbe.shrink{
  img{
    width:50%;
  }
}
footer{
  background-color: rgba($dvrblau,0.08);
  padding-top:4rem;
  position: relative;
  // background-color: $white;
  margin-bottom:2rem;
  .bordertopfooter{
    padding-top:10px;
    border-top: solid 4px $dvrblau;
    // padding: 20px 0px;
  }
  img{
    // width:60%;
    margin:0 auto;
  }
  h4{
    text-align: left;
    font-size: $body-fontsize-p;
  }
  p{
    font-size:$body-fontsize-p*0.8 !important;
  // color:$white;
  // text-align: center !important;
  }
  ul{
    list-style:none;
    // color:$white;
    text-align: center ;
    li{
      display:inline-block;
      text-transform: uppercase;
      padding:0rem 1rem;
      a{
        color:inherit;
      }
    }
  }
}
footer img.youtube{
  // background-color: red;
  width:50px;
}
footer a:hover{
  color:$orange;
  transition: 0.6s ease-out;
}
footer a img:hover{
  opacity: 0.6;
  transition: 0.6s ease-out;
}
footer .footerimg{
  // background-color: green;
  // position: absolute;
  // bottom:0px;
  // margin-top:35px;
  margin-left:0px;
  // background-color: green;
  width:100px !important;
   @include breakpoint(medium up){
     position: absolute;
     bottom:0px;
     margin-left:35px;
     width:100px !important;
   }
  // margin:0 auto;
  img{
    width:100%;
    // background-color: red;
  }
}
footer .footerimgsmall{
  width:76px !important;
  img{
    width:100%;
  }
}
footer .footerimg:hover{
  background-color: white !important;
}
.clear{
  clear: both;
}

p.footercopy{
    // margin-top:-50px !important;
    // margin-top:30px;
  @include breakpoint(medium up){
    text-align: right;
    padding-bottom:40px;
    font-weight: $body-font-medium;
  }
}
a.button{
    background-color:$blau;
}
a.button:hover{
      // background-color:$blau;
    // background-color: lighten($blau,5%) !important;
}
/* Rectangle Out */
.hvr-rectangle-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  background: rgba(0,0,0,0);
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.hvr-rectangle-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hvr-rectangle-out:hover, .hvr-rectangle-out:focus, .hvr-rectangle-out:active {
  color: white;
}
.hvr-rectangle-out:hover:before, .hvr-rectangle-out:focus:before, .hvr-rectangle-out:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.bordertop{
  // border-top:solid 5px $dvrblau;
  padding-top:20px;
  margin-top:40px;
}
footer .bgwhite{
  background-color: white;
}

// Flip-Card
.card-flip {
  margin-bottom: 40px;
  perspective: 1000px;
  &:hover .flip,
  &.hover .flip {
    transform: rotateY(180deg);
  }
}
.card-flip,
.front,
.back {
  width: 100%;
  min-height: 300px;
  // max-height: 271px;
  // margin:0 auto;
}
.card-section{
    // padding-bottom:3rem;
    text-align: center;
}
.flip {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.card{
    background-color: $orange;
    border-bottom:none;
    padding-top:10%;
    img{
      width:45%;
      margin:0 auto;
    }
}
.backcard {
  background-color: $gelb;
  p{
    margin-left:6%;
    margin-right:6%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.front {
  z-index: 2;
  transform: rotateY(0deg);
  background-color: $orange;
  h3{
  color:$white;
  }
}
.back {
  transform: rotateY(180deg);
  background-color: $gelb;
}

// Akkordeon
ul.accordion{
  margin:2rem 0rem;
  width:80%;

}
.accordion-title {
    font-size: $body-fontsize-p*1.2;
    background-color: $orange;
    color:$white;
    font-weight:$body-font-medium;
}
.accordion-title:hover, .accordion-title:focus {
    background-color: $orange;
    color:$white;
}
.accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+';
}
.accordion-content {
    padding: 2rem;
    background-color: $gelb;
    color:$green;
    font-weight:$body-font-medium;
}
