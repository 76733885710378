$body-fontsize: 100% !default;

// @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500');

@font-face {
    font-family: 'ubuntu_lightregular';
    src: url('../fonts/ubuntu-light-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ubuntu_mediumregular';
    src: url('../fonts/ubuntu-medium-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'ubunturegular';
    src: url('../fonts/ubuntu-regular-webfont.woff2') format('woff2'),
         url('../fonts/ubuntu-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



$body-fontfamily: 'Ubuntu', sans-serif;


$body-fontsize-h1: 2.4rem;
// $body-fontsize-h2: 2rem;
$body-fontsize-h2: 2.2rem;
$body-fontsize-h3: 1.6rem !default;
$body-fontsize-h4: 2rem !default;
// $body-fontsize-h4: 1.2vw !default;
$body-fontsize-h5: 1.125em !default;
$body-fontsize-h6: 1.125em !default;
$body-fontsize-p: 1.2rem;
// $body-fontsize-p: 2.0833333333333335rem;
$body-fontsize-p-auszeichnung: 2em !default;

$font-size-mediumdown: 4vmin;

$body-font-bold: 500;
$body-font-medium: 400;
$body-font-leight: 300;

$body-font-letterspacing: 0.01em;


html,
body {
    font-size: $body-fontsize;
    font-family: $body-fontfamily;
    color: $black;
    font-weight: $body-font-medium;
}

a{
  color:inherit;
  text-decoration: none;
}

a.button{
  letter-spacing: 0.03rem;
  font-size: $body-fontsize-p*0.8;
}
p, dl, dt, dd, ol, ul, li,
fieldset, form, legend, caption, input, select, tbody, tfoot, thead {
    font-size: $body-fontsize-p*0.9!important;
    font-weight: $body-font-leight;
    @include breakpoint(medium up) {
    font-size: $body-fontsize-p !important;
    text-align: left;
    letter-spacing: 0.05rem;
  }
}

p strong{
    font-weight: $body-font-medium;
}
h1 {
  font-size:$body-fontsize-h2;
  line-height: $body-fontsize-h2/1.4rem;
  font-family: $body-fontfamily;
  margin-top:0.6rem;
  text-align: left;
  letter-spacing: $body-font-letterspacing;
  @include breakpoint(medium down) {
    font-size: $body-fontsize-h2 *0.6 !important;
  }
}

h2 {
  font-size:$body-fontsize-h2;
  // line-height: $body-fontsize-h2/1.4rem;
  font-family: $body-fontfamily;
  margin-top:0.6rem;
  text-align: left;
  letter-spacing: $body-font-letterspacing;
  @include breakpoint(medium down) {
    font-size: $body-fontsize-h2 *0.6 !important;
  }
}
h2.white,
p.white{
    color:$white;
  }
h3{
  font-family: $body-fontfamily;
  font-size: $body-fontsize-h3;
  font-weight: $body-font-bold;
  margin-top:-10px;
  @include breakpoint(medium down) {
    font-size: $font-size-mediumdown !important;
  }
}

h4,
h5,
h6 {
  font-family: $body-fontfamily;
  font-size: $body-fontsize-h4;
  font-weight: $body-font-bold;
  margin-top:-10px;
  @include breakpoint(medium down) {
    font-size: $font-size-mediumdown !important;
  }
}

.bold{
  font-weight:700;
}
