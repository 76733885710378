

$akzent: $black;
// $primary: red;
// $secondary: blue;

$white: $white;
// $grau: #e5e5e5;
//
// $hellblau: rgba(164,189,204,0.6);
// $blau: rgba(0,115,189,1);
// $dunkelblau: rgba(0,46,76,1);
//
//
// $green:rgba(106,138,138,1);
// $purple:rgba(109,94,122,1);
// $blue:rgba(98,140,170,1);
// $orange:rgba(181,145,81,1);


$gelb:rgba(255,196,69,1);
$blau:rgba(112,207,245,1); //$hellblau
$orange:rgba(245,97,43,1);

$green:rgba(68,140,55,1);

$dvrblau:rgba(20,56,127,1);
