.navibar{ //komplette navi
  background-color: rgba($white,0.8);
  width:100%;
  height:92px;
  top: 0;
  position: fixed;
  z-index: 999;
  color:$akzent !important;
  transition: all 0.3s ease;
  li{
      font-size: 0.8rem !important;
      @include breakpoint(large up){
        font-size: 1rem !important;
      }
  }
}

.title-bar {
    padding: 0.5rem;
    background-color: rgba($white,0);
    color:$akzent !important;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    right:10px;
    top:10px;
}
.title-bar-title {
    display: inline-block;
    vertical-align: middle;
    font-weight: $body-font-medium;
}
.menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $akzent;
    box-shadow: 0 7px 0 $akzent, 0 14px 0 $akzent;
    content: '';
}
.is-drilldown{
  // background-color: red;
}
.dropdown.menu .is-active > a {
    // background: rgba(0,0,0,0) !important;
    // background-color: rgba(0,0,0,0) !important;
    // color: rgba(0,0,0,0) !important;
}

.dropdown.menu .is-active > a {
    // background: rgba(0,0,0,0) !important;
    // background-color: rgba(0,0,0,0) !important;
    // color: #0a0a0a !important;
}
.menu .is-active > a {
    // background: rgba(0,0,0,0) !important;
    // background-color: rgba(0,0,0,0) !important;
    // color: rgba(0,0,0,0) !important;
}

.is-dropdown-submenu {
    position: absolute;
    top: 90px;
    left: 100%;
    z-index: 1;
    display: none;
    min-width: 300px;
    border: 1px solid rgba(0,0,0,0);
    background-color: rgba($white,1);
}

  @media print, screen and (min-width: 64em){ //Pfeile
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
      display: block;
      width: 0;
      height: 0;
      border: inset 6px;
      content: '';
      border-bottom-width: 0;
      border-top-style: solid;
      border-color: $akzent transparent transparent;
      right: 5px;
      left: auto;
      margin-top: -3px;
  }
}
.drilldown .is-drilldown-submenu-parent > a::after {//Pfeile
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $akzent;
}
.drilldown .js-drilldown-back > a::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $akzent transparent transparent;
    border-left-width: 0;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.75rem;
    border-left-width: 0;
}

ul.menu{
    transition: all 0.3s ease;
  @include breakpoint(large up){
  top:30px;}
  li .button{
    background-color: rgba(0,0,0,0) !important;
  }
}

.drilldown{
    margin-top: 92px;
    transition: all 0.3s ease;
 a{
     background-color: rgba($white,0.8);
 }
}


.menu.move{
  @include breakpoint(large up){
  top:0px;
  transition: all 0.3s ease;
  }
}
.drilldown.shrink{
  margin-top: 40px;
  transition: all 0.3s ease;
}
// body.shrink {
.navibar.shrink {
  // min-height: 35px;
  height:50px;
  min-height: 35px;
  // background-color: red;
  font-size:0.6rem;
  transition: all 0.3s ease;
  a{
    font-size:$body-fontsize-p*0.8;
    transition: all 0.3s ease;
  }
  ul.menu{
    padding-top:10px;
  transition: all 0.3s ease;}
  .sponsorukbg{
    margin-top:0px;
    transition: all 0.3s ease;
    img{
      width:70px;
      transition: all 0.3s ease;
    }
  }
  .sponsordvr{
    margin-right:10px;
    transition: all 0.3s ease;
    img{
      width:30px;
      transition: all 0.3s ease;
    }
  }
}
.sponsorlogos{
  // width:100px;
  height:50px;
  position: absolute;
  right:40px;
  top:10px;
  img{
    float:left;
  }
  .sponsorukbg{
    position: relative;
    float:left;
    margin-left:10px;
    margin-top:15px;
    img{
      width:110px;
      transition: all 0.3s ease;
    }
  }
  .sponsordvr{
    position: relative;
    float:left;
    margin-right:10px;
    img{
      width:70px;
      transition: all 0.3s ease;
    }
  }
}
